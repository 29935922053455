<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0">
        <dx-data-grid
          id="prepMaterialPurchaseGrid"
          ref="prepMaterialPurchaseGridRef"
          :data-source="dataSource"
          :height="setHeight"
          :cache-enabled="false"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :hover-state-enabled="true"
          :row-alternation-enabled="false"
          @initialized="onInitialized"
          @content-ready="onContentReady"
          @toolbar-preparing="onToolbarPreparing($event)"
          @row-removed="refresh"
          @editor-preparing="onEditorPreparing"
        >
          <template #filterToolbar>
            <div class="d-flex">
              <dx-util-button icon="back" type="default" class="mx-half" @click="$router.go(-1)" />
            </div>
          </template>
          <dx-sorting mode="single" />
          <dx-remote-operations :paging="true" :filtering="false" :sorting="false" :summary="false" />
          <dx-search-panel :visible="true" :width="300" placeholder="Search..." />
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
          <!--endregion ::DataGird base components -->
          <dx-column data-field="title" />
          <dx-column data-field="costGroup" caption="Cost Group" />
          <dx-column data-field="vendorName" caption="Vendor" />
          <dx-column data-field="cost" caption="Purchase Cost" :format="'$ #,##0.##'" :width="120" />
          <dx-column data-field="salePrice" caption="Sale Price" :format="'$ #,##0.##'" :width="120" />
          <dx-column data-field="quantity" caption="Quantity" :width="120" :format="'#,##0'" />
          <dx-column data-field="createdTime" caption="Created At" data-type="date" :width="120" alignment="right" />
          <dx-column data-field="notes" />
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="120" :fixed="true" fixed-position="left" />
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                type="warning"
                icon="bi bi-pencil-square"
                class="mr-half"
                hint="Edit Prep Material Purchase"
                @click="onClickEdit(data)"
              />
              <dx-util-button
                type="danger"
                icon="bi bi-trash"
                hint="Delete Prep Material Purchase"
                @click="onClickDelete(data)"
              />
            </div>
          </template>
        </dx-data-grid>
      </div>
    </div>
    <div>
      <material-purchase-form
        :component-id="materialPurchaseFormComponentId"
        :material-purchase-id="selectedMaterialPurchaseId"
        @emit-form-closed="selectedMaterialPurchaseId=null"
        @emit-form-saved="onPurhcaseFormSaved"
      />
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import prepMaterialService from '@/http/requests/prep-material/prepMaterialService'
import { confirm } from 'devextreme/ui/dialog'
import prepMaterialPurchaseService from '@/http/requests/prep-material/prepMaterialPurchaseService'
import Pager from '@core/dev-extreme/utils/pager'
import { prepMaterialPurchaseDataSource } from './materialPurchaseStore'

export default {
  components: {
    'material-purchase-form': () => import('../components/PrepMaterialPurchaseForm.vue'),
  },
  mixins: [GridBase],
  props: {
    qPrepMaterialId: {
      type: Number || null,
      default: null,
    },
  },
  data: () => ({
    dataSource: prepMaterialPurchaseDataSource,
    filters: {
      query: '',
    },
    items: [],
    materialPurchaseFormDefault: {},
    materialPurchaseForm: {
      id: null,
      companyId: '',
      title: '',
      cost: null,
      sale: null,
      costGroup: null,
      vendorName: '',
      quantity: null,
      notes: '',
      prepMaterialId: '',
      selectedMaterilPurchaseId: null,
    },
    materialPurchaseFormComponentId: '',
    costGroups: [],
    selectedMaterialPurchaseId: null,
  }),
  computed: {
    dataGrid() {
      return this.$refs.prepMaterialPurchaseGridRef.instance
    },
    materialPurchasePopup() {
      const popup = this.$refs.prepMaterialPurchasePopupRef.instance
      return popup
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleSubmit(e.event)
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.materialPurchasePopup.hide()
        },
      }
    },
    costOptions() {
      return {
        format: '$ #,##0.##',
      }
    },
    itemOptions() {
      return {
        dataSource: this.items,
        valueExpr: 'title',
        searchExpr: 'title',
        onSelectionChanged: e => {
          this.materialPurchaseForm.prepMaterialId = e?.selectedItem?.id
          this.materialPurchaseForm.costGroup = e?.selectedItem?.costGroup
          this.materialPurchaseForm.vendorName = e?.selectedItem?.vendorName
        },
        onValueChanged: e => {
          if (e?.value?.length === 3) {
            this.getMaterialItems(e.value)
          }
        },
      }
    },

    pageableResult: {
      get() {
        return this.pageable.toCreatePayload()
      },
    },
  },
  created() {
    this.getPrepMaterialPurchaseData()
    // this.initialLoad()
  },
  mounted() {
    this.initialLoad()
  },
  methods: {
    onPurhcaseFormSaved() {
      this.getByQuery()
    },
    onEditorPreparing(e) {
      if (e.parentType !== 'searchPanel') return
      e.editorOptions.onValueChanged = arg => {
        const query = arg.component.option('value')
        if (!query) {
          this.filters.query = query.trim()
          e.component.searchByText('')
          this.getByQuery()
        }
      }
      e.editorOptions.onKeyDown = arg => {
        if (arg.event.keyCode === 13) {
          let query = arg.component.option('value')
          query = query.trim()
          arg.component.option('value', query)
          this.filters.query = query
          this.getByQuery()
          e.component.searchByText(query)
        }
        if (arg.event.keyCode === 27) {
          e.component.searchByText('')
          arg.component.option('value', '')
          this.filters.query = ''
          this.getByQuery()
        }
      }
    },
    getByQuery() {
      const filters = {
        // companyId: 0,
        q: this.filters.query,
        materialId: this.qPrepMaterialId,
      }
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.reload()
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          text: 'Prep Materials',
          icon: 'icon icon-psh-logistic-3',
          type: 'success',
          onClick: () => {
            this.$router.push({ name: 'route-material-items' })
          },
        },
        location: 'after',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      // toolbarItems.push({
      //   widget: 'dxButton',
      //   options: {
      //     icon: 'add',
      //     onClick: () => {
      //       this.materialPurchaseFormComponentId = uuidv4()
      //     },
      //   },
      //   location: 'after',
      // })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.refresh()
          },
        },
        location: 'after',
      })
    },
    initialLoad() {
      this.dataGrid.clearFilter('search')
      const filters = {
        // companyId: 0,
        q: this.filters.query,
        materialId: this.qPrepMaterialId,
      }
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.load()
    },
    refresh(e) {
      const dataSource = this.dataGrid.getDataSource()
      dataSource.reload()
    },
    getMaterialItems(searchStr) {
      const pager = new Pager()
      prepMaterialService.fetchAllMatchingName(searchStr, pager.staticPageable).then(result => {
        this.items.splice(0, this.items.length)
        this.items.push(...result)
      }).then(() => {
        const form = this.$refs.materialPurchaseFormRef
        const editor = form.instance.getEditor('title')
        editor.focus()
      })
    },
    onClickEdit(e) {
      const materialPurchaseId = e.row.data.id
      this.materialPurchaseFormComponentId = uuidv4()
      this.selectedMaterialPurchaseId = materialPurchaseId
      // this.onClickCreateOrEdit(e)
    },
    onClickCreateOrEdit(e) {
      Object.assign(this.materialPurchaseForm, this.materialPurchaseFormDefault)
      const id = (e && typeof e !== 'undefined') ? e.row.key : null
      if (typeof id !== 'undefined' && id !== null) {
        this.materialPurchaseForm = {
          id: id,
          prepMaterialId: e.row.data.prepMaterialId,
          vendorName: e.row.data.vendorName,
          costGroup: e.row.data.costGroup,
          title: e.row.data.title,
          cost: e.row.data.cost,
          sale: e.row.data.sale,
          notes: e.row.data.notes,
          quantity: e.row.data.quantity,
        }
      }
      this.materialPurchasePopup.show()
    },
    async onClickDelete(e) {
      const confirmResult = confirm(
        "Are you sure you want to delete this prep material purchase record? You won't be able to revert this!",
        'Confirmation',
      )
      confirmResult.then(async dialogResult => {
        if (dialogResult) {
          const result = await prepMaterialPurchaseService.deleteById(e.row.data.id)
          if (result) {
            this.refresh()
          }
        }
      })
    },
    isValidId(id) {
      return id !== undefined && id !== null && id !== '' && id !== 0
    },
    handleSubmit(e) {
      const form = this.$refs.materialPurchaseFormRef.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        // const dataSource = this.dataGrid.getDataSource()
        const id = this.materialPurchaseForm.id
        const data = {
          id: id,
          title: this.materialPurchaseForm.title,
          prepMaterialId: this.materialPurchaseForm.prepMaterialId,
          cost: this.materialPurchaseForm.cost,
          sale: this.materialPurchaseForm.sale,
          quantity: this.materialPurchaseForm.quantity,
          notes: this.materialPurchaseForm.notes,
        }
        if (this.isValidId(id)) {
          prepMaterialPurchaseService.update(data).then(() => {
            form.resetValues()
            this.materialPurchaseForm.id = null
            this.materialPurchasePopup.hide()
          }).then(() => {
            this.refresh()
          })
        } else {
          prepMaterialPurchaseService.create(data).then(() => {
            this.materialPurchaseForm.id = null
            this.materialPurchasePopup.hide()
          }).then(() => {
            this.refresh()
          })
        }
      }
    },
    onHiding() {
      const form = this.$refs.materialPurchaseFormRef.instance
      this.isEdit = false
      form.resetValues()
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
